html {
  scroll-behavior: smooth !important;
  min-height: 100vh;
  background-color: $bgColor;
}

body {
  margin: 0;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 300;
  #root {
    overflow: hidden;
  }
}

input,
button,
img,
textarea {
  border: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

img {
  vertical-align: middle;
}

.MuiMenu-paper {
  border-radius: 12px !important;
}

.ql-snow {
  &.ql-toolbar {
    background-color: #f6f6fd;
    border-radius: 4px;
    border: none !important;
    .ql-formats {
      margin-right: 10px !important;
    }
  }

  &.ql-container {
    font-family: 'Inter', sans-serif !important;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-tooltip {
    left: 0px !important;
    box-shadow: 0px 4px 12px rgba(67, 84, 221, 0.3) !important;
    border: none !important;
    font-family: 'Inter', sans-serif;
    border-radius: 4px;
    input {
      border-radius: 4px;
    }
  }
}

pre,
code {
  padding: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  background-color: #eee;
  white-space: pre-wrap;
}

code {
  padding: 2px;
}

blockquote {
  border-left: 1px;
  border-color: #eee;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.add-button-header {
  margin-right: 20px;
  height: 35px;
  border-radius: 12px;
  text-transform: 'capitalize';
  padding: 5px 18px;
}

.add-button-icon-header {
  position: 'relative';
  top: 1;
}

.text-field-outlined {
  width: '100%';
  margin: 0px 8px;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #4354dd;
      }
    }
  }
  .MuiOutlinedInput-input {
    padding: 12px;
    font-size: 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
  }
}
