circle.nodeCircle {
  cursor: pointer;
}

image.nodeImage, image.ResourceImage {
  cursor: pointer;
}

text {
  pointer-events: none;
}
